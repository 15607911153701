import React from "react";

const Devise = () => {
  return (
    <div class="entete-gauche">
      <p>Votre Solution pour une Construction Moderne</p>
      <span class="icone gauche">
        <i class="fa-solid fa-phone "></i> +243990892295
      </span>
    </div>
  );
};

export default Devise;
